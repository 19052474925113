export default [
  {key: 'pix', value: "PIX"},
  {key: 'teddoc', value: "TED/DOC"},
  {key: 'deposito', value: "Depósito"},
  {key: 'boleto', value: "Boleto"},
  {key: 'pagarme', value: "Cartão - PagarMe"},
  {key: 'rede', value: "Cartão - Rede"},
  {key: 'cielo', value: "Cartão - Cielo"},
  {key: 'infinitypay', value: "Cartão - InfinityPay"},
  {key: 'cliente', value: "Cartão - Cliente"},
  {key: 'pagamento_faturado', value: "Faturado"},
  {key: 'fornecedor', value: "Cartão - Cliente Portal Fornecedor"},
  {key: 'transferencia', value: "Transferência"},

]